<template>
  <div
    class="b-connect blue--bg"
    data-test="how-to-connect-block"
  >
    <div class="container">
      <h2 class="b-connect__title headline-2 text-center">
        {{ titleResult }}
      </h2>
      <p class="b-connect__subtitle title-2 text-center">
        {{ subtitleResult }}
      </p>

      <div class="b-connect__steps d-flex">
        <div
          class="b-connect__step d-flex flex-column align-start"
          data-number="1"
        >
          <img
            loading="lazy"
            class="b-connect__step-img"
            :src="
              imgUrl(
                '/media/uploads/blog/mstep1.png',
                120,
                '',
              )
            "
            alt=""
          >
          <p class="b-connect__step-title title-4">
            Укажите свой адрес
          </p>
          <p class="b-connect__step-subtitle title-5 gray-b--text">
            Введите адрес, по которому вы бы хотели подключить домашний интернет
          </p>
          <d-btn
            class="title-4 b-connect__step-btn"
            color="white-primary"
            @click="checkAddress"
          >
            Проверить адрес
          </d-btn>
        </div>
        <div
          class="b-connect__step d-flex flex-column align-start"
          data-number="2"
        >
          <img
            loading="lazy"
            class="b-connect__step-img"
            :src="
              imgUrl(
                '/media/uploads/blog/mstep2.png',
                120,
                '',
              )
            "
            alt=""
          >
          <p class="b-connect__step-title title-4">
            Выберите тариф
          </p>
          <p class="b-connect__step-subtitle title-5 gray-b--text">
            Сравните тарифные планы доступных провайдеров домашнего интернета
          </p>
        </div>
        <div
          class="b-connect__step d-flex flex-column align-start"
          data-number="3"
        >
          <img
            loading="lazy"
            class="b-connect__step-img"
            :src="
              imgUrl(
                '/media/uploads/blog/mstep3.png',
                120,
                '',
              )
            "
            alt=""
          >
          <p class="b-connect__step-title title-4">
            Оформите заявку
          </p>
          <p class="b-connect__step-subtitle title-5 gray-b--text">
            Оставьте заявку на подключение и ожидайте звонка оператора
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { imgUrl, textAggReplace } from '~/helpers'
import { useCities } from '~/store/cites/cities.store'

const props = defineProps({
  title: {
    type: String,
    default: 'Как подключить интернет %CITY_LOC%?',
  },
  subtitle: {
    type: String,
    default:
      'Подключить домашний интернет %CITY_LOC% просто. Следуйте нашей инструкции',
  },
  textAgg: {},
})

const ctx = useNuxtApp()
const cityStore = useCities()
const getAddressText = computed(() => cityStore.getAddressText)

const titleResult = computed(() =>
  textAggReplace(props.title, getAddressText.value, props.textAgg),
)
const subtitleResult = computed(() =>
  textAggReplace(props.subtitle, getAddressText.value, props.textAgg),
)
const checkAddress = () => {
  ctx.$event('addressDialog', { redirect: true, label: 'blog-connect' })
}
</script>

<style scoped lang="scss">
.b-connect {
  padding: 64px 0;
  @media (max-width: getBreakpoint(tablet)) {
    padding: 40px 0 40px;
  }

  &__title {
    margin-bottom: 8px;
    @media (max-width: getBreakpoint(tablet)) {
      text-align: left;
    }
  }
  &__subtitle {
    margin-bottom: 32px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 28px;
      text-align: left;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 22px;
    }
  }

  &__steps {
    counter-reset: item;
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 28px;
    }
  }
  &__step {
    flex: 1 1 33.33%;
    background: color(white);
    padding: 24px;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    @media (max-width: getBreakpoint(tablet)) {
      padding: 16px;
    }
    //@media (max-width: getBreakpoint(mobile-lg)) {
    //  padding-left: 88px;
    //}
    //@media (max-width: getBreakpoint(mobile-md)) {
    //  padding-left: 72px;
    //}
    * {
      position: relative;
    }

    &:before {
      content: attr(data-number);
      position: absolute;
      font-size: 200px;
      font-weight: 700;
      line-height: 140%;
      color: color(gray-darker);
      right: -20px;
      top: -75px;
      @media (max-width: getBreakpoint(tablet)) {
        font-size: 160px;
        right: -15px;
        top: -60px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 150px;
        top: -58px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: 140px;
        top: -55px;
      }
    }

    &-img {
      width: 80px;
      height: 80px;
      object-fit: contain;
      object-position: center;
      margin-bottom: 16px;
      //@media (max-width: getBreakpoint(tablet)) {
      //  position: absolute;
      //  left: 0;
      //  top: 0;
      //}
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 70px;
        height: 70px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 60px;
        height: 60px;
      }
    }

    &-title {
      margin-bottom: 8px;
      @media (max-width: getBreakpoint(tablet)) {
        text-align: left;
        margin-bottom: 4px;
      }
    }
    &-subtitle {
      @media (max-width: getBreakpoint(tablet)) {
        text-align: left;
      }
    }
    &-btn {
      height: 38px;
      margin-top: 16px;
    }

    &:not(:last-child) {
      margin-right: 20px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-right: 0;
        margin-bottom: 32px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        margin-bottom: 36px;
      }
    }
  }

  &:deep(.ac-dark) {
    width: 980px;
    padding-left: 60px;
    padding-right: 60px;
    .ac__title {
      margin-bottom: 12px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding: 24px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      padding: 16px;
    }
  }
}
</style>
